<template>
  <li class="target-item" :key="item">
    <div class="target-item-left">
      <img src="@/assets/img/work/work-okr-ellipse.svg" />
      <span>KR {{ index + 1 }}:</span>

      <!-- 新增和编辑显示输入框 -->
      <el-input
        v-if="templateType === OKR_STATUS_TYPE.ADD || isEdit"
        v-model="itemData.name"
        :placeholder="`请输入关键结果${index + 1}`"
        maxlength="100"
      ></el-input>
      <template v-else>
        <template v-if="shouldEllipsis">
          <Tooltip
            max-width="100%"
            :lines="1"
            :content="itemData.name"
            placement="top" />
        </template>
        <template v-else>
          <div class="item-kr-content word-wrap-block">{{ itemData.name }}</div>
        </template>
      </template>
    </div>

    <div
      v-if="showWeight"
      class="item-weight"
    >
      权重
      <!-- 新增和编辑显示输入权重 -->
      <el-input-number
        v-if="templateType === OKR_STATUS_TYPE.DRAFT || isEdit || templateType === OKR_STATUS_TYPE.ADD"
        v-model="itemData.weights"
        :precision="0"
        :min="0"
        value-on-clear="min"
        :max="getMaxNumber(index)"
        :controls="false"
        :disabled="itemData.disabled"
        @change="changeWeight(index)"
        @blur="inputNumberBlur(index)"
      />
      <span v-else>{{ itemData.weights }}</span>
      <span> %</span>
    </div>

    <!-- 新增和编辑显示删除 -->
    <el-icon
      v-if="templateType === OKR_STATUS_TYPE.ADD || isEdit"
      class="close-icon cursor-pointer"
      @click="deleteOkrItem(index, item)"
      ><CloseBold
    /></el-icon>

    <!-- 进度条 -->
    <div
      class="item-progress"
      v-if="
        (templateType === OKR_STATUS_TYPE.PASS && !isEdit) ||
        (templateType === OKR_STATUS_TYPE.EVALUATION && !isEdit)
      "
    >
      <ProgressCircle :schedule="itemData.schedule || 0" />
      <el-input-number
        v-if="
          !disabled ||
          (templateType === OKR_STATUS_TYPE.EVALUATION &&
            (route.name === 'myokr' || route.name === 'okrHistory'))
        "
        v-model="itemData.schedule"
        :disabled="
          disabled && route.name !== 'myokr' && route.name !== 'okrHistory'
        "
        :precision="0"
        :min="0"
        value-on-clear="min"
        :max="100"
        :controls="false"
        @change="editItemSchdule($event, itemData.kr_id || itemData.id)"
      />
      <span v-else class="active-text-color schdule">{{ itemData.schedule }}</span>
      <span class="active-text-color">%</span>
    </div>
  </li>
</template>

<script>
import { computed, reactive, toRefs } from 'vue'
import { useRoute } from 'vue-router'
import { setOkrSchedule } from '@/apis/okr.js'
import { dialogMsg } from '@/utils/dialogMsg.js'
import { OKR_STATUS_TYPE } from '@/utils/constant.js'
import ProgressCircle from './ProgressCircle.vue'

export default {
  name: 'OkrItem',
  props: {
    item: { // 数据
      type: Object,
      required: true,
      default: () => {
        return {
          name: '',
          weights: 0,
          schedule: 0
        }
      }
    },
    templateType: { // 模板类型
      type: String,
      required: true
    },
    isEdit: { // 是否编辑
      type: Boolean,
      default: false
    },
    index: { // 索引
      type: Number,
      required: true
    },
    okrList: { // 列表数据
      type: Array,
      required: true
    },
    disabled: { // 是否禁用
      type: Boolean,
      default: false
    },
    showWeight: { // 是否显示权重
      type: Boolean,
      default: true
    },
    shouldEllipsis: { // 是否需要省略
      type: Boolean,
      default: false
    },
    showScheduleToast: { // 是否显示提示
      type: Boolean,
      default: true
    }
  },
  components: {
    ProgressCircle
  },
  emits: ['deleteItem', 'changeWeight', 'editSchedule'],
  setup (props, { emit }) {
    const route = useRoute()
    const state = reactive({
      itemData: computed(() => props.item)
    })

    // 获取最大权重
    const getMaxNumber = (currentIndex) => {
      const tempList = props.okrList.filter((i, index) => index < currentIndex)
      let tempWeight = 0
      tempList.map(i => (tempWeight += i.weights))
      return 100 - tempWeight
    }

    // 修改权重
    const changeWeight = (index) => {
      emit('changeWeight', index)
    }

    // 删除kr
    const deleteOkrItem = (index, item) => {
      emit('deleteItem', index, item)
    }

    // 如果不输入为0 权重默认设置为0
    const inputNumberBlur = (currentIndex) => {
      const okrItem = props.okrList.filter((i, index) => index === currentIndex)[0]
      if (!okrItem.weights) okrItem.weights = 0
    }

    // 修改当前进度
    const editItemSchdule = (currentValue, id) => {
      if (route.path.includes('Journal')) return emit('editSchedule')
      setOkrSchedule({ id: [id], schedule: [currentValue] }).then(res => {
        if (res.code === 0) {
          props.showScheduleToast && dialogMsg('success', res.msg)
          emit('editSchedule', res.data.schedule)
        } else {
          dialogMsg('error', res.msg)
        }
      })
    }

    return {
      ...toRefs(state),
      getMaxNumber,
      changeWeight,
      deleteOkrItem,
      inputNumberBlur,
      editItemSchdule,
      route,
      OKR_STATUS_TYPE
    }
  }
}
</script>

<style scoped lang="less">
.target-item {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #f1f1f1;
  .target-item-left {
    display: flex;
    align-items: center;
    min-width: 0;
    flex: 1;

    img {
      margin-right: 8px;
    }

    & > span {
      width: 45px;
      font-weight: 400;
      font-size: 14px;
      color: #7972f0;
    }
    & > .el-input {
      width: 580px;
      :deep(.el-input__inner) {
        color: #333;
        font-size: 14px;
        border: none;
        height: 52px;
        padding-left: 1px;
        line-height: 18px;
        &::placeholder {
          font-size: 14px;
          color: #999999;
          line-height: 18px;
        }
      }
    }
    & > .item-kr-content {
      width: 580px;
      color: #333;
      font-size: 14px;
      border: none;
      padding: 17px 6px 17px 1px;
      min-height: 52px;
      line-height: 18px;
    }
  }
  & > .item-kr-content {
    width: 580px;
    color: #333;
    font-size: 14px;
    border: none;
    padding: 15px 32px 15px 1px;
    line-height: 18px;
    word-break: break-all;
  }
  & > .item-weight {
    display: flex;
    align-items: center;
    font-weight: 400;
    font-size: 13px;
    color: #999999;
    width: 100px !important;
    text-align: center;
    span:first-child {
      display: inline-block;
      text-align: center;
      margin-left: 2px;
      font-weight: 400;
      font-size: 13px;
    }
  }
  // 数字输入框
  .item-weight :deep(.el-input-number),
  .item-progress :deep(.el-input-number) {
    font-size: 13px;
    width: 36px;
    height: 24px;
    margin-left: 3px;
    margin-right: 2px;
    color: @default-text-color;
    .el-input {
      line-height: 0;
      width: 36px;
      height: 24px;
      line-height: 24px;
    }
    .el-input__inner {
      background: #f6f9fa;
      border: 1px solid #f1f1f1;
      border-radius: 4px;
      padding: 0;
      height: 24px;
      line-height: 24px;
      color: #333;
      font-size: 13px;
    }
    span {
      font-size: 14px;
    }
  }
  .close-icon {
    margin-left: 60px;
    color: #999;
    width: auto;
    height: auto;
    transform: translateX(5px);
    svg {
      font-size: 18px;
      margin-top: 16px;
    }
  }
}

.item-progress{
  display: flex;
  align-items: center;
  :deep(.el-input__inner) {
    color: @active-text-color !important;
  }
  span {
    font-size: 13px;
    margin-left: 4px;
  }
  .schdel {
    min-width: 23px;
    text-align: center;
  }
}

.item-progress .all-target-weight {
  font-weight: 400 !important;
  font-size: 13px !important;
  margin-right: 0 !important;
  min-width: 38px;
}

.schdule {
  display: inline-block;
  min-width: 16px;
  text-align: center;
}
</style>

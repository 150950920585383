<template>
    <el-progress
      :stroke-width="strokeWidth"
      :style="{ '--circle-size': size + 'px' }"
      type="circle"
      :percentage="schedule"
      :color="color"
    >
      <span v-show="showText">{{ schedule }}%</span>
    </el-progress>
</template>

<script>
export default {
  name: 'ProgressCircle',
  props: {
    schedule: {
      type: Number,
      required: true
    },
    color: {
      type: String,
      default: '#7972f0'
    },
    strokeWidth: {
      type: Number,
      default: 24
    },
    size: {
      type: Number,
      default: 20
    },
    showText: {
      type: Boolean,
      default: false
    }
  }
}
</script>
<style scoped lang="less">
.el-progress {
  vertical-align: middle;
  :deep(.el-progress-circle) {
    width: var(--circle-size) !important;
    height: var(--circle-size) !important;
  }
}
</style>
